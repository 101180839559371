.logo {
	display: inline-block;
	width: 120px;
	opacity: 0;
	transition: opacity 500ms;

	svg {
		fill: $orange;
	}

	&--visible {
		opacity: 1;
	}
}

@include above ($desk-bk) {

	.logo {
		width: 160px;
	}

}