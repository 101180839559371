.footer {
	position: relative;

	padding-top: 60px;
	padding-bottom: 20%;
	font-size: 0;

	background-color: $orange;
	overflow: hidden;

	transition: background-color 500ms;

	.footer__column {
		color: $white;
		text-transform: uppercase;
		transition: color 500ms;

		&--1 {
			display: none;
		}

		&--2 {
			margin-bottom: 40px;
		}

		.footer__smallList {
			margin: 0;
			padding: 0;
			list-style: none;
			font-family: $narrow-font-family;
			font-size: 1.2rem;
			line-height: 1.4em;
			
			a {
				color: $white;
				text-decoration: none;
				transition: color 500ms;
			}

			&--mob {
				margin-top: 36px;
			}
		}

		.footer__address {
			font-family: $narrow-font-family;
			font-size: 1.4rem;
			line-height: 1.6em;
			letter-spacing: 1px;

			span {
				display: block;
			}

			a {
				color: $white !important;
			}
		}

		.footer__social {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				vertical-align: middle;
				margin: 0 10px;

				&:first-child {
					margin-left: 0;
				}
			}
			
			a {
				display: block;
				text-decoration: none;

				svg {
					fill: $white;
					transition: transform 500ms, fill 500ms;
				}

				&:hover {
					svg {
						transform: scale(1.2);
					}
				}

				&.footer__icon--instagram {
					margin-top: 3px;
				}
			}
		}
	}

	.footer__logo {
		position: absolute;
		left: 50%;
		bottom: -4px;

		width: 100%;

		transform: translateX(-50%);
		transition: all 500ms;

		svg {
			fill: $white;
			transition: fill 500ms;
		}
	}
}

@include above ($desk-bk) {

	.footer {
		.footer__column {
			display: inline-block;
			vertical-align: bottom;

			&--1 {
				width: 60%;
			}
	
			&--2 {
				width: 25%;
				margin-bottom: 0;
			}
	
			&--3 {
				width: 15%;
			}

			.footer__smallList {
				&--mob {
					display: none;
				}
			}
		}

		.footer__logo {
			bottom: -116px;
		}
	}

}