.clients {
	opacity: 0;
	transition: opacity 500ms;

	.clients__title {
		margin: 12px 0 24px;
		padding-top: 100px;
		font-family: $grand-font-family;
		font-size: 3rem;
		color: $white;
		text-decoration: underline;
		text-align: center;
		text-transform: lowercase;
	}

	.clients__box {
		margin: 0 0 40px;
		overflow: hidden;
	}

	.clients__list {
		margin: -1px;
		padding: 0;
		font-size: 0;
		list-style: none;

		li {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			border: 1px solid rgba($color: $white, $alpha: .15);

			img {
				width: 100%;
				opacity: 1;
			}
		}
	}

	&--visible {
		opacity: 1;
	}
}

@include above ($large-desk-bk) {

	.clients {
		.clients__title {
			margin: 50px 0;
			font-size: 6rem;
		}

		.clients__box {
			margin: 40px 0;
		}

		.clients__list {
			li {
				width: calc(100% / 7);
			}
		}
	}

}

@include above ($large-desk-bk) {

	.clients {
		.clients__title {
			font-size: 6.8rem;
		}
	}

}