.areas {
	position: relative;
	margin-top: -70px;
	padding: 120px 0 80px;
	text-align: center;
	background-color: $blue;
	overflow: hidden;

	.areas__bg {
		position: absolute;
		top: 50%;
		left: 50%;

		width: 100%;
		height: 100%;

		background-color: $blue;

		transform: translate(-50%, -50%);
	}

	.areas__icon {
		position: relative;
		display: inline-block;
		margin-bottom: 15px;
		font-family: $main-font-family;
		font-size: 2.4rem;
		color: $green;
		z-index: 3;

		&:after {
			content: '+';

			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: 3px;
			font-size: 3rem;
			transform: translate(-50%, -50%);
		}
	}

	ul {
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;
		z-index: 3;

		li {
			text-align: center;

			a {
				position: relative;
				font-family: $grand-font-family;
				font-size: 2.4rem;
				color: $green;
				line-height: 1.4em;
				text-decoration: none;

				&:after {
					content: '';

					position: absolute;
					bottom: 0;
					left: 50%;
					
					display: block;
					width: 0;
					border-bottom: 3px solid $green;

					transform: translateX(-50%);
					transition: width 250ms;
				}

				&:hover {
					&::after {
						width: 100%;
					}
				}
			}
		}
	}

	.areas__content {
		max-width: 1024px;
		max-height: 0;
		margin: 0 auto;
		padding:  0 24px;
		overflow: hidden;
		transition: max-height 500ms, margin 500ms;

		p {
			font-family: $main-font-family;
			font-size: 1.4rem;
			color: $white;
			line-height: 1.6em;
			text-transform: uppercase;
			letter-spacing: 1.2px;
		}

		&--active {
			max-height: 600px;
			margin: 40px auto;
		}
	}
}

@include above ($desk-bk) {

	.areas {
		margin-top: -100px;
		padding: 220px 0 180px;

		ul {
			li {
				a {
					font-size: 5rem;

					&:after {
						border-bottom: 6px solid $green;
					}
				}
			}
		}

		.areas__content {
			p {
				font-size: 1.6rem;
				line-height: 1.6em;
			}
		}
	}

}

@include above ($large-desk-bk) {

	.areas {
		padding: 240px 0 200px;

		.areas__icon {
			margin-bottom: 20px;
			font-size: 2.8rem;

			&:after {
				margin-top: 2px;
				font-size: 3.4rem;
			}
		}

		ul {
			li {
				a {
					font-size: 8rem;
					line-height: 1.2em;
				}
			}
		}

		.areas__content {
			p {
				font-size: 1.8rem;
			}
		}
	}

}