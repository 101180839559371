.about {
	padding: 40px 0;
	background-color: $festival;

	h3 {
		margin: 0 0 40px;
		font-family: $grand-font-family;
		font-size: 3.6rem;
		color: $white;
		text-decoration: underline;
	}

	svg {
		display: none;
	}

	p {
		margin: 12px 0;
		font-family: $main-font-family;
		font-size: 1.4rem;
		color: $black;
		line-height: 1.6em;
		text-transform: uppercase;
		letter-spacing: 1.1px;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.about__spacing {
		line-height: 1rem;
	}
}

@include above ($desk-bk) {

	.about {
		padding: 80px 0;
		font-size: 0;

		.about__column {
			display: inline-block;
			vertical-align: top;
			width: 50%;

			&--first {
				padding-right: 40px;
			}
		}

		svg {
			display: block;
			max-width: 378px;
			margin-top: 60px;
			fill: $white;
		}

		h3 {
			font-size: 7.8rem;
			line-height: 1.3em;
		}

		p {
			font-size: 1.6rem;
			line-height: 1.6em;
		}
	}

}

@include above ($large-desk-bk) {

	.about {
		padding: 140px 0 100px;

		.about__column {
			width: 54%;

			&--first {
				width: 46%;
				padding-right: 50px;
			}
		}

		p {
			font-size: 1.7rem;
		}
	}

}