.mainSlider {
	position: relative;
	font-size: 0;
	overflow: hidden;

	.mainSlider__list {
		position: relative;
		margin: 0;
		padding: 0;
		z-index: 2;

		.mainSlider__item {
			position: relative;
			overflow: hidden;
			min-height: 600px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, .35);
				z-index: 3;
			}
		}
	}

	.mainSlider__background {
		width: 100%;
		height: 100%;
		background-color: $dusty;
		background-size: cover;
		background-position: center;

		.mainSlider__backgroundImage {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
		}

		video {
			display: none;
			/*position: absolute;
			top: 0;
			left: 50%;
			width: 100%;
			transform: translateX(-50%);*/
		}
	}

	.mainSlider__content {
		position: absolute;
		top: 50%;
		left: -60px;
		max-width: 260px;
		font-family: $grand-font-family;
		color: $white;
		z-index: 3;
		transform: translateY(-50%);
		transition: left 2s;

		span {
			font-size: 1.2rem;
			line-height: 1em;
			text-transform: uppercase;
		}

		h2 {
			margin: 12px 0 0;
			font-size: 3rem;
			line-height: 1em;
			text-decoration: underline;
		}

		a {
			display: inline-block;
			margin-top: 20px;
			padding: 10px 16px 8px;
			font-size: 1.2rem;
			color: $white;
			line-height: 1em;
			text-transform: uppercase;
			text-decoration: none;
			border: 1px solid $white;
			transition: color 250ms, background-color 250ms;

			svg,
			span {
				display: inline-block;
				vertical-align: middle;
			}

			span {
				margin-top: 5px;
				margin-left: 5px;
			}

			svg {
				fill: $white;
				transition: fill 250ms;
			}

			&:hover {
				color: $black;
				background-color: $white;

				svg {
					fill: $black;
				}
			}
		}
	}
	
	.slick-current {
		.mainSlider__content {
			left: 48px;
		}
	}

	.slick-arrow {
		position: absolute;
		top: 50%;

		margin: 0;
		padding: 0;

		background-color: initial;
		border: initial;
		transform: translateY(-50%);
		z-index: 4;
		cursor: pointer;

		svg {
			fill: $festival;
			transition: fill 150ms;
		}

		&:hover {
			svg {
				fill: $white;
			}
		}
	}

	.slick-prev {
		left: 16px;
	}

	.slick-next {
		right: 16px;
	}

	.mainSlider__dots {
		position: absolute;
		right: 60px;
		top: 50%;
		z-index: 3;
		transform: translateY(-50%);

		.slick-dots {
			padding: 0;

			li {
				display: block;
				margin-bottom: 10px;

				button {
					width: 8px;
					height: 8px;
					padding: 0;
					background-color: $white;
					border: initial;
					border-radius: 50%;
					opacity: 0.5;
					cursor: pointer;
					transition: transform 250ms;

					&:hover {
						transform: scale(1.2);
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.slick-active {
					button {
						opacity: 1;
					}
				}
			}
		}
	}

	.mainSlider__grayCover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 50%;
		background-color: $white;
		z-index: 3;
		transition: height 1s;
	}

	.mainSlider__yellowCover {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		background-color: $orange;
		z-index: 3;
		transition: height 1s;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		background-color: $orange;
	}

	&--started {
		.mainSlider__grayCover {
			height: 0;
		}

		.mainSlider__yellowCover {
			height: 0;
		}
	}

	&--case {
		.mainSlider__item {
			height: 100vh;
		}
	}
}

@include above ('600px') {

	.mainSlider {
		.mainSlider__content {
			span, a {
				font-size: 1.4rem;
			}

			h2 {
				font-size: 3.2rem;
			}
		}
	}

}

@include above ($desk-bk) {

	.mainSlider {
		.mainSlider__list {
			.mainSlider__item {
				&--active {
					.mainSlider__content {
						//left: 100px;
					}
				}
			}
		}

		.mainSlider__content {
			max-width: 720px;

			span {
				font-size: 2rem;
			}
	
			h2 {
				font-size: 4rem;
			}
	
			a {
				font-size: 1.6rem;
			}
		}

		.slick-arrow svg {
			width: 28px;
			height: 28px;
		}

		.slick-current {
			.mainSlider__content {
				left: 74px;
			}
		}

		.mainSlider__nav {
			display: block;
		}
	}

}

@include above ('1024px') {

	.mainSlider {
		.mainSlider__background {
			video.videoLoaded {
				position: relative;
				display: block;
			}

			.mainSlider__backgroundImage {
				//display: none;
			}
		}

		&--home {
			/*.mainSlider__list {
				.mainSlider__item {
					&:before {
						background-color: rgba(0, 0, 0, 0);
					}
				}
			}*/
		}
	}

}

@include above ('1460px') {

	.mainSlider {
		.mainSlider__list {
			.mainSlider__item {
				//height: 900px;
			}
		}

		.mainSlider__content {
			max-width: 760px;

			span {
				font-size: 1.8rem;
			}

			h2 {
				font-size: 6.8rem;
			}
		}

		.mainSlider__background {
			img {
				//width: 100%;
				//height: auto;
			}

			video {
				//width: 100%;
				//height: auto;
			}
		}
	}

}