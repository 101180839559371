@keyframes scaleUp {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes scaleDown {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeInTop {
	0% {
		opacity: 0;
		top: -100%;
	}
	100% {
		opacity: 1;
		top: 50%;
	}
}

@keyframes fadeOutTop {
	0% {
		opacity: 1;
		top: 50%;
	}
	100% {
		opacity: 0;
		top: -100%;
	}
}

@keyframes slideInRight {
	0% {
		right: -100%;
	}
	100% {
		right: 0;
	}
}

@keyframes slideOutRight {
	0% {
		right: 0;
	}
	100% {
		right: -100%;
	}
}

@keyframes slideInLeft {
	0% {
		left: -100%;
	}
	100% {
		left: 0;
	}
}

@keyframes slideOutLeft {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}

@keyframes spinLoader {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}