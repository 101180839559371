.logoGroup {
	display: inline-block;
	vertical-align: middle;
	width: 36px;
	opacity: 0;
	transition: all 500ms;

	svg {
		fill: $white;
	}

	&--visible {
		opacity: 1;
	}

	&--active,
	&:hover {
		transform: scale(.9);
		opacity: .5;
	}
}

@include above ($desk-bk) {

	.logoGroup {
		width: 48px;
	}

}