.aboutCase {
	padding: 40px 0;
	background-color: $festival;
	transition: background-color 250ms;

	.aboutCase__logo {
		display: none;
	}

	h2 {
		margin: 0 0 40px;
		font-family: $grand-font-family;
		font-size: 3.6rem;
		color: $white;
		text-decoration: underline;
	}

	h3 {
		margin: 0 0 40px;
		font-family: $grand-font-family;
		font-size: 2.4rem;
		color: $black;
		transition: color 250ms;
	}

	p {
		margin: 12px 0;
		font-family: 'Agrandir', sans-serif;
		font-weight: normal;
		font-size: 1.4rem;
		color: $black;
		line-height: 1.6em;
		letter-spacing: 1.1px;
		transition: color 250ms;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.aboutCase__play {
		font-family: $grand-font-family;
		font-size: 2rem;
		color: $black;
		text-decoration: none;
		transition: color 250ms;

		svg, span {
			display: inline-block;
			vertical-align: middle;
		}

		svg {
			fill: $black;
			transition: fill 250ms;
		}

		span {
			margin-top: 5px;
			margin-left: 10px;
			text-transform: uppercase;
		}
	}
}

@include above ($desk-bk) {

	.aboutCase {
		padding: 140px 0;
		font-size: 0;

		.aboutCase__column {
			display: inline-block;
			vertical-align: top;
			width: 50%;

			&--first {
				padding-right: 60px;
			}
		}

		.aboutCase__logo {
			display: block;
			max-width: 140px;
			margin-bottom: 40px;
		}

		h2 {
			margin: 0 0 80px;
			font-size: 5rem;
		}

		h3 {
			font-size: 4rem;
		}

		p {
			font-size: 1.6rem;
			line-height: 1.6em;
		}
	}

}

@include above ($large-desk-bk) {

	.aboutCase {

		p {
			font-size: 1.8rem;
		}
	}

}