.pageLoading {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	z-index: 12;
	transition: height 500ms;

	&--hidden {
		height: 0;
	}
}