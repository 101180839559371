.people {
	padding: 50px 0;
	background-color: $orange;
	opacity: 0;
	transition: opacity 500ms;

	.people__title {
		margin: 0 0 40px;
		padding-top: 100px;
		font-family: $grand-font-family;
		font-size: 2.4rem;
		color: $white;
		text-decoration: underline;
		text-align: center;
		text-transform: lowercase;
	}

	.people__columns {
		font-size: 0;

		.people__column {
			position: relative;

			&--1 {
				display: none;
			}
		}
	}

	.people__slider {
		.people__item {
			position: relative;
			width: 33.33%;
			height: 500px;
			background-color: $dusty;
			background-size: cover;
			background-position: top center;
			cursor: pointer;

			.people__description {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 24px;
				font-family: 'Agrandir Narrow', sans-serif;
				color: $white;
				text-transform: uppercase;
				letter-spacing: 1px;
				//background-color: rgba($color: $black, $alpha: .35);
				z-index: 2;
			}

			h3 {
				margin: 0 0 2px;
				font-family: $grand-font-family;
				font-size: 1.4rem;
				font-weight: normal;

				text-shadow: 0 0 10px rgba($black, .5);
			}

			p {
				margin: 0;
				font-family: 'Agrandir Narrow', sans-serif;
				font-size: 1.2rem;

				text-shadow: 0 0 10px rgba($black, .5);
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color: $white, $alpha: 0);
				transition: background-color 500ms;
			}

			&--guimarconi {
				background-image: url('../img/people/guimarconi.jpg');
			}

			&--pablomanzotti {
				//background-image: url('../img/people/pablomanzotti.jpg');
			}

			&--laurabrasileiro {
				//background-image: url('../img/people/laurabrasileiro.jpg');
			}

			&--guilhermemarconi {
				background-image: url('../img/people/guilhermemarconi.jpg');
			}

			&--periclesdouglas {
				background-image: url('../img/people/periclesdouglas.jpg');
			}

			&--deborahfornazari {
				background-image: url('../img/people/deborahfornazari.jpg');
			}

			&--adrianasantana {
				background-image: url('../img/people/adrianasantana.jpg');
			}

			&--ludmila {
				background-image: url('../img/people/ludmila.jpg');
			}

			/*&:hover {
				&:after {
					background-color: rgba($color: $black, $alpha: .25);
				}
			}*/
		}

		.slick-arrow {
			position: absolute;
			top: 50%;

			margin: 0;
			padding: 0;

			background-color: initial;
			border: initial;

			transform: translateY(-50%);
			z-index: 4;
			cursor: pointer;

			svg {
				fill: $white;
				transition: fill 250ms;
			}

			&:hover {
				svg {
					fill: $blue;
				}
			}
		}

		.slick-prev {
			left: 10px;
		}

		.slick-next {
			right: 10px;
		}
	}

	.people__contents {
		margin-top: 30px;

		.people__contentItem {
			font-family: 'Agrandir', sans-serif;
			font-weight: normal;
			font-size: 1.4rem;
			color: $black;
			line-height: 1.6em;
			letter-spacing: 1.1px;

			p {
				margin: 12px 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&--visible {
		opacity: 1;
	}

	.breakLineDesk {
		display: none;
	}
}

@include above ('380px') {

	.people {
		.people__slider {
			.people__item {
				//height: 680px;
			}
		}
	}

}

@include above ('580px') {

	.people {
		.people__slider {
			.people__item {
				height: 680px;
			}
		}
	}

}

@include above ($tablet-bk) {

	.people {
		.people__slider {
			.people__item {
				height: 820px;
			}
		}
	}

}

@include above ($desk-bk) {

	.people {
		.people__title {
			margin: 0 0 50px;
			font-size: 6rem;
		}

		.people__columns {
			.people__column {
				display: inline-block;
				vertical-align: middle;

				&--1 {
					width: 33%;
					height: 560px;
					padding: 20px;
					background-color: $festival;

					.people__label {
						font-family: $grand-font-family;
						font-size: 1.4rem;
						color: $dusty;
						text-transform: uppercase;

						&--left {
							float: left;
						}

						&--right {
							float: right;
						}

						&--bottomLeft {
							position: absolute;
							left: 20px;
							bottom: 20px;
						}

						&--bottomRight {
							position: absolute;
							right: 20px;
							bottom: 20px;
						}
					}

					.people__box {
						position: absolute;
						top: 50%;
						left: 50%;

						width: calc(100% - 40px);
						max-width: 274px;
						height: 50%;

						background-image: url('../img/cooler-gradient.png');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;

						transform: translate(-50%, -50%);
					}
				}

				&--2 {
					//width: 67%;
					width: 100%;
				}
			}
		}

		.people__slider {
			.people__item {
				height: 560px;

				p {
					font-size: 1.3rem;
					//height: 2em;
				}

				.people__description {
					padding: 20px 40px;
				}

				/*&:after {
					background-color: rgba($color: $black, $alpha: .35);
				}*/
			}

			.slick-current {
				.people__item {
					&:after {
						//background-color: rgba($color: $white, $alpha: .2);
					}
				}
			}
		}

		.people__contents {
			width: 67%;
			margin-top: 48px;
			margin-left: 33%;

			.people__contentItem {
				font-size: 1.6rem;
				line-height: 1.6em;
			}
		}
	}

}

@include above ($large-desk-bk) {

	.people {
		padding-bottom: 60px;

		.people__title {
			font-size: 6.8rem;
		}

		.people__columns {
			.people__column {
				&--1 {
					width: 20%;
					height: 660px;
				}

				&--2 {
					//width: 80%;
					width: 100%;
				}
			}
		}

		.people__slider {
			.people__item {
				height: 660px;

				.people__description {
					padding: 20px 40px 60px;
				}
			}
		}

		.people__contents {
			width: 80%;
			margin-top: 60px;
			margin-left: 20%;

			.people__contentItem {
				max-width: 66.66%;
				font-size: 1.8rem;
			}
		}

		.breakLineDesk {
			display: block;
		}
	}

}