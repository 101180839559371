.menuToggle {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 36px;
	opacity: 0;
	transition: opacity 500ms;

	.menuToggle__square {
		fill: $orange;
		//transform: scale(1);
		//transform-origin: center;
		opacity: 1;
		transition: opacity 500ms 2s, fill 250ms;
	}

	.menuToggle__rectA {
		fill: $orange;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 2s, fill 250ms;
	}

	.menuToggle__rectB {
		fill: $orange;
		transform: rotate(60deg);
		transform-origin: center;
		transition: transform 2s, fill 250ms;
	}

	.menuToggle__rectC {
		fill: $orange;
		transform: rotate(-60deg);
		transform-origin: center;
		transition: transform 2s, fill 250ms;
	}

	&--menu {
		.menuToggle__square {
			//transform: scale(0);
			opacity: 0;
			transition: opacity 500ms;
		}

		.menuToggle__rectA {
			transform: rotate(90deg);
			transform-origin: center;
			transition: transform 2s 500ms, fill 250ms;
		}

		.menuToggle__rectB {
			transform: rotate(90deg) translateX(75px);
			transform-origin: center;
			transition: transform 2s 500ms, fill 250ms;
		}

		.menuToggle__rectC {
			transform: rotate(-90deg) translateX(75px);
			transform-origin: center;
			transition: transform 2s 500ms, fill 250ms;
		}
	}

	&--visible {
		opacity: 1;
	}
}