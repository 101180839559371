.caseNumbers {
	position: relative;
	margin-top: -50px;
	padding: 100px 0 60px;
	text-align: center;
	background-color: $pigeon;
	overflow: hidden;

	.caseNumbers__bg {
		position: absolute;
		top: 50%;
		left: 50%;

		width: 100%;
		height: 100%;

		background: $gradient;
		background-size: 100%;
		background-position: center;
		transform: translate(-50%, -50%);
	}

	.caseNumbers__content {
		position: relative;
		z-index: 4;
	}

	h3 {
		margin: 0 0 40px;
		font-family: $grand-font-family;
		font-size: 2.4rem;
		color: $black;
		text-transform: uppercase;
		transition: color 250ms;
	}

	p {
		margin: 12px 0;
		font-family: $main-font-family;
		font-size: 1.4rem;
		color: $black;
		line-height: 1.6em;
		text-transform: uppercase;
		letter-spacing: 1.1px;
		transition: color 250ms;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.caseNumbers__numberList {
		margin: 50px 0 0;
	}

	.caseNumbers__numberItem {
		margin: 0 0 40px;

		span {
			display: block;

			&:first-child {
				font-family: $grand-font-family;
				font-size: 4rem;
				line-height: 4.5rem;
				text-transform: uppercase;
			}

			&:last-child {
				font-family: $main-font-family;
				font-size: 1.4rem;
				text-transform: uppercase;
				letter-spacing: 1.1px;
			}
		}

		.special {
			font-family: Arial;
			font-weight: bold;
			font-size: 48px;
		}
	}

	.caseNumbers__numberParagraph {
		margin: 20px 0 50px;
		padding: 30px 0;
		font-family: $grand-font-family;
		font-size: 2rem;
		line-height: 3rem;
		text-align: center;
		text-transform: uppercase;
		border-top: 2px solid $black;
		border-bottom: 2px solid $black;

		span {
			display: block;
			max-width: 460px;
			margin: 0 auto;
		}
	}

	.caseNumbers__numberColumns {
		.caseNumbers__numberColumn {
			&:last-child {
				margin-top: 30px;
			}
		}
	}

}

@include above ($tablet-bk) {

	.caseNumbers {
		text-align: left;

		.caseNumbers__numberList {
			margin: 50px -40px 0;
			font-size: 0;
		}

		.caseNumbers__numberItem {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 80px);
			margin: 0 40px 40px;
		}
	}

}

@include above ($desk-bk) {

	.caseNumbers {
		margin-top: -100px;
		padding: 200px 0 80px;

		.caseNumbers__content {
			max-width: 1024px;
			margin: 0 auto;
		}

		h3 {
			font-size: 4.2rem;
		}

		p {
			font-size: 1.6rem;
			line-height: 1.6em;
		}

		.caseNumbers__numberItem {
			span {
				&:first-child {
					font-size: 5rem;
				}

				&:last-child {
					font-size: 1.6rem;
				}
			}
		}

		.caseNumbers__numberParagraph {
			font-size: 3rem;
			line-height: 4rem;

			span {
				max-width: 700px;
			}
		}

		.caseNumbers__numberColumns {
			font-size: 0;

			.caseNumbers__numberColumn {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				padding-right: 30px;

				&:last-child {
					margin-top: 0;
				}
			}
		}
	}

}

@include above ($large-desk-bk) {

	.caseNumbers {
		p {
			font-size: 1.8rem;
		}

		.caseNumbers__numberItem {
			span {
				&:last-child {
					font-size: 1.8rem;
				}
			}
		}
	}

}