.menu {
	position: fixed;
	left: 0;
	top: -100%;
	width: 100%;
	height: 0;
	background-color: $blue;
	z-index: 9;
	transition: height 500ms, top 500ms;

	&--visible {
		top: 0;
		height: 100%;
		min-height: 400px;
	}

	.menu__list {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;

		li {
			a {
				position: relative;
				font-family: $grand-font-family;
				font-size: 3.6rem;
				color: $green;
				line-height: 1.2em;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: 1px;
				transition: color 500ms;

				span {
					display: none;
				}

				&:after {
					content: '';

					position: absolute;
					bottom: 0;
					left: 50%;
					
					display: block;
					width: 0;
					border-bottom: 3px solid $green;

					transform: translateX(-50%);
					transition: width 250ms;
				}

				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.menu__label {
		display: none;
	}
}

@include above ($desk-bk) {

	.menu {
		.menu__background {
			&:before {
				width: 75%;
			}
		}

		.menu__list {
			li {
				a {
					position: relative;
					font-size: 8rem;

					span {
						display: block;

						position: absolute;
						top: 50%;
						left: 50%;

						font-size: 20rem;
						color: $green;
						white-space: nowrap;
						opacity: 0;

						transform: translate(-50%, -30%);
						transition: opacity 250ms;
					}

					&:after {
						border-bottom: 6px solid $green;
					}

					&:hover {
						span {
							opacity: .1;
							transition: opacity 500ms 250ms;
						}
					}
				}
			}
		}

		.menu__label {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			font-family: $grand-font-family;
			font-size: 1.2rem;
			color: $pink;
			line-height: 1em;
			letter-spacing: 1.4px;
			z-index: 12;
			transform: rotate(-90deg) translate(0, 340%);
		}
	}

}