.headerNav {
	position: relative;
	display: block;

	height: 100px;
	margin-bottom: -100px;
	padding: 32px 0;

	font-size: 0;
	background-color: $blue;
	
	z-index: 10;
	transition: background-color 500ms;

	.headerNav__left,
	.headerNav__center,
	.headerNav__right {
		display: inline-block;
		vertical-align: middle;
	}

	.headerNav__left,
	.headerNav__right {
		width: 15%;
	}

	.headerNav__center {
		width: 70%;
		text-align: center;
	}

	.headerNav__right {
		text-align: right;
	}

	&--fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
	}

	&--transparent {
		background-color: initial;

		&.headerNav--fixed {
			background-color: $festival;

			.logo,
			.logoGroup {
				svg {
					fill: $dusty;
				}
			}

			.menuToggle {
				.menuToggle__gif {
					&--yellow {
						opacity: 0;
					}
			
					&--white {
						opacity: 0;
					}
			
					&--gray {
						opacity: 1;
					}
				}
			}
		}
	}

	&--orange {
		background-color: $orange;

		.logoGroup,
		.logo {
			svg {
				fill: $white;
			}
		}

		.menuToggle {
			.menuToggle__square,
			.menuToggle__rectA,
			.menuToggle__rectB,
			.menuToggle__rectC {
				fill: $white;
			}
		}
	}

	&--green {
		background-color: $green;

		.logoGroup {
			svg {
				fill: $white;
			}
		}
	}

	&--menuVisible {
		background-color: $blue;

		.logoGroup {
			svg {
				fill: $white;
			}
		}

		.logo {
			svg {
				fill: $orange;
			}
		}

		.menuToggle {
			.menuToggle__square,
			.menuToggle__rectA,
			.menuToggle__rectB,
			.menuToggle__rectC {
				fill: $orange;
			}
		}
	}

}

@include above ($desk-bk) {

	.headerNav {
		padding: 30px 0;

		.headerNav__left,
		.headerNav__center,
		.headerNav__right {
			width: 33.33%;
		}
	}

}