.loading-area {
	position: relative;

	&:before {
		@extend .loading-spinner;
		content: '';
	}
}

.loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 30px;
	height: 30px;
	border: 1px dashed $dusty;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	animation: spinLoader 5000ms infinite linear;
}