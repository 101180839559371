.container {
	position: relative;
	max-width: 1920px;
	height: 100%;
	margin: 0 auto;
	padding: 0 30px;
}

.small-container {
	@extend .container;

	max-width: 1084px;
}

.medium-container {
	@extend .container;

	max-width: 1400px;
}

@include above ($desk-bk) {

	.container {
		padding: 0 60px;
	}

	.small-container {
		max-width: 1144px;
	}

	.medium-container {
		max-width: 1460px;
	}

}