.theme-dark {

	.headerNav {
		background-color: $black;

		.logo {
			svg {
				fill: $festival;
			}
		}

		.logoGroup {
			svg {
				fill: $white;
			}
		}

		.menuToggle {
			.menuToggle__gif {
				&--yellow {
					opacity: 1;
				}
		
				&--white {
					opacity: 0;
				}
		
				&--gray {
					opacity: 0;
				}
			}
		}
	}

	.menu {
		background-color: $black;

		.menu__background {
			&:before {
				background-color: $black;
			}
		}

		.menu__list {
			li {
				a {
					color: $festival;

					&:after {
						border-color: $festival;
					}
				}
			}
		}
	}

	.aboutCase {
		background-color: $black;

		h2 {
			color: $white;
		}

		h3 {
			color: $festival;
		}

		p {
			color: $white;
		}
	}

	.caseFullVideo {
		background-color: $black;
	}

}