.caseGallery {
	position: relative;
	font-size: 0;
	background-color: $festival;
	transition: background-color 250ms;

	.caseGallery__slider {
		margin: 0;
		padding: 0;
		z-index: 4;

		img {
			display: block;
			width: 100%;
		}

		.slick-arrow {
			position: absolute;
			top: 50%;

			margin: 0;
			padding: 0;

			background-color: initial;
			border: initial;

			transform: translateY(-50%);
			z-index: 4;
			cursor: pointer;

			svg {
				fill: $white;
				transition: fill 250ms;
			}

			&:hover {
				svg {
					fill: $orange;
				}
			}
		}

		.slick-prev {
			left: 10px;
		}

		.slick-next {
			right: 10px;
		}
	}

	.caseGallery__legend {
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 100%;
		margin: 10px 0;
		font-family: $main-font-family;
		font-size: 1.2rem;
		color: $white;
		letter-spacing: 1px;
		text-align: center;
		transform: translateX(-50%);
		z-index: 4;
	}

	&--negativeMargin {
		margin-top: -20px;
	}
}

@include above ($desk-bk) {

	.caseGallery {
		.caseGallery__slider {
			.slick-arrow {
				svg {
					width: 28px;
					height: 28px;
				}
			}
		}

		.caseGallery__legend {
			font-size: 1.4rem;
		}

		&--negativeMargin {
			margin-top: -100px;
		}
	}

}