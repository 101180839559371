@mixin above ($width) {
	@media only screen and (min-width: $width) {
		@content;
	}
}

@mixin aboveHeight ($height) {
	@media only screen and (min-height: $height) {
		@content;
	}
}

@mixin below ($width) {
	@media only screen and (max-width: $width) {
		@content;
	}
}

@mixin belowHeight ($height) {
	@media only screen and (max-height: $height) {
		@content;
	}
}