.works {
	padding-top: 100px;
	text-align: center;
	opacity: 0;
	transition: opacity 500ms;

	.works__item {
		position: relative;
		margin-bottom: 24px;

		a {
			position: relative;
			display: block;
		}

		.works__itemCover {
			position: relative;
			height: 300px;
			background-color: $dusty;
			background-size: cover;
			background-position: center;
			overflow: hidden;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, .35);
				z-index: 3;
				transition: background-color 500ms;
			}
		}

		.works__itemTitle {
			position: absolute;
			top: 50%;
			left: 30px;
			padding-right: 30px;
			font-family: $grand-font-family;
			color: $white;
			text-align: left;
			transform: translateY(-50%);
			z-index: 3;
			transition: margin-left 1s;
	
			h2 {
				margin: 12px 0 0;
				font-size: 2.4rem;
				line-height: 1em;
				text-decoration: underline;
				cursor: pointer;
			}

			small {
				display: inline-block;
				margin-top: 20px;
				padding: 10px 16px 8px;
				font-size: 1.2rem;
				color: $white;
				line-height: 1em;
				text-transform: uppercase;
				text-decoration: none;
				border: 1px solid #fff;
				cursor: pointer;
				transition: color 250ms, background-color 250ms;

				&:hover {
					color: $black;
					background-color: $white;
				}
			}
		}
	}

	&--more {
		padding: 25px 0;
		background-color: $pink;

		.works__item {
			margin-bottom: 0;
			padding: 0 20px;
		}

		.slick-arrow {
			position: absolute;
			top: 50%;

			margin: 0;
			padding: 0;

			background-color: initial;
			border: initial;
			transform: translateY(-50%);
			z-index: 4;
			cursor: pointer;

			svg {
				fill: $orange;
				transition: fill 150ms;
			}

			&:hover {
				svg {
					fill: $white;
				}
			}
		}

		.slick-prev {
			left: 10px;
		}

		.slick-next {
			right: 10px;
		}
	}

	&--visible {
		opacity: 1;
	}
}

@include above ('600px') {

	.works {
		.works__item {
			.works__itemTitle {
				span, small {
					font-size: 1.4rem;
				}

				h2 {
					font-size: 3.2rem;
				}
			}
		}
	}

}

@include above ($desk-bk) {

	.works {
		font-size: 0;

		.works__item {
			margin-bottom: 50px;

			.works__itemCover {
				width: 100%;
				height: 500px;
			}

			.works__itemTitle {
				left: 60px;
				//max-width: 800px;
		
				h2 {
					font-size: 4rem;
				}

				small {
					font-size: 1.6rem;
				}
			}

			&:hover {
				.works__itemCover {
					&:before {
						background-color: rgba(0, 0, 0, .40);
					}
				}

				.works__itemTitle {
					//margin-left: 10px;
				}
			}

			&--big {
				.works__itemCover {
					width: 100%;
					height: 640px;
				}
			}

			&--small {
				display: inline-block;
				vertical-align: top;
				width: calc(50% - 25px);

				&:nth-child(2n) {
					margin-left: 50px;
				}
			}
		}

		&--more {
			padding: 50px 0;

			.works__item {
				margin-bottom: 0;
				padding: 0 25px;
			}

			.slick-prev {
				left: 30px;
			}
	
			.slick-next {
				right: 30px;
			}
		}
	}

}

@include above ('1460px') {

	.works {
		.works__item {
			&--big {
				.works__itemTitle {
					small {
						font-size: 1.8rem;
					}
	
					h2 {
						font-size: 7rem;
					}
				}
			}
		}
	}

}