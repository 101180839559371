.theme-festival {
	
	.headerNav {
		background-color: $festival;

		.logo,
		.logoGroup {
			svg {
				fill: $dusty;
			}
		}

		.menuToggle {
			.menuToggle__gif {
				&--yellow {
					opacity: 0;
				}
		
				&--white {
					opacity: 0;
				}
		
				&--gray {
					opacity: 1;
				}
			}
		}
	}

}