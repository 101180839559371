@font-face {
	font-family: 'Agrandir Grand';
	src: url('../fonts/Agrandir-GrandHeavy.eot');
	src: url('../fonts/Agrandir-GrandHeavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Agrandir-GrandHeavy.woff2') format('woff2'),
		url('../fonts/Agrandir-GrandHeavy.woff') format('woff'),
		url('../fonts/Agrandir-GrandHeavy.ttf') format('truetype'),
		url('../fonts/Agrandir-GrandHeavy.svg#Agrandir-GrandHeavy') format('svg');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Agrandir Narrow';
	src: url('../fonts/Agrandir-Narrow.eot');
	src: url('../fonts/Agrandir-Narrow.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Agrandir-Narrow.woff2') format('woff2'),
		url('../fonts/Agrandir-Narrow.woff') format('woff'),
		url('../fonts/Agrandir-Narrow.ttf') format('truetype'),
		url('../fonts/Agrandir-Narrow.svg#Agrandir-Narrow') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Agrandir';
    src: url('../fonts/Agrandir-Regular.eot');
    src: url('../fonts/Agrandir-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Agrandir-Regular.woff2') format('woff2'),
        url('../fonts/Agrandir-Regular.woff') format('woff'),
        url('../fonts/Agrandir-Regular.ttf') format('truetype'),
        url('../fonts/Agrandir-Regular.svg#Agrandir-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Agrandir';
    src: url('../fonts/Agrandir-TextBold.eot');
    src: url('../fonts/Agrandir-TextBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Agrandir-TextBold.woff2') format('woff2'),
        url('../fonts/Agrandir-TextBold.woff') format('woff'),
        url('../fonts/Agrandir-TextBold.ttf') format('truetype'),
        url('../fonts/Agrandir-TextBold.svg#Agrandir-TextBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}