.contact {
	background-color: $orange;

	.contact__title {
		margin: 12px 0 24px;
		padding-top: 100px;
		font-family: $grand-font-family;
		font-size: 2.4rem;
		color: $white;
		text-decoration: underline;
		text-align: center;
		text-transform: lowercase;
	}

	.contact__address {
		font-family: $narrow-font-family;
		font-size: 1.4rem;
		line-height: 1.6em;
		text-align: center;
		color: $white;
		letter-spacing: 1px;
		text-transform: uppercase;

		span {
			display: block;
		}

		a {
			color: $white !important;
		}
	}

	.contact__phone {
		padding-top: 15px;

		&--smallCaps {
			text-transform: lowercase;
		}
	}

	.contact__social {
		display: inline-block;
		margin: 24px 0 32px;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 4px;

			a {
				display: block;

				svg {
					display: block;
					fill: $white;
					transition: transform 500ms;
				}

				&:hover {
					svg {
						transform: scale(1.2);
					}
				}

				/*&.contact__icon--linkedin {
					margin-top: -1px;
				}*/
			}
		}
	}

	.contact__map {
		font-size: 0;
		background-color: $orange;
		
		iframe {
			opacity: .75;
		}
	}
}

@include above ($desk-bk) {

	.contact {
		.contact__title {
			margin: 50px 0;
			font-size: 4rem;
		}

		.contact__address {
			font-size: 1.6rem;
			line-height: 1.6em;
		}

		.contact__social {
		}
	}

}

@include above ($large-desk-bk) {

	.contact {
		.contact__title {
			//font-size: 8rem;
		}
	}

}