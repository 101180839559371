$black: #000000;
$white: #ffffff;

$alto: #d0d0d0;
$dusty: #979797;
$festival: #fbf070;
$pigeon: #B2C8DE;

$orange: #fa6a41;
$blue: #1e51fb;
$green: #00fbd4;
$pink: #ffbbed;