@import 'slick-carousel/slick/slick';

@import './normalize';
@import './core/core';

* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
}

body {
	font-family: $main-font-family;
	font-weight: 400;
	background-color: $dusty;

	&.orange-background {
		background-color: $orange;
	}

	&.green-background {
		background-color: $green;
	}
}

// global components
@import './components/pageLoading';
@import './components/container';
@import './components/br';

@import './components/group';
@import './components/headerNav';

@import './components/logo';
@import './components/logoGroup';

@import './components/menu';
@import './components/menuToggle';

@import './components/mainSlider';
@import './components/about';
@import './components/aboutDiverti';
@import './components/aboutCase';
@import './components/areas';
@import './components/caseGallery';

@import './components/clients';
@import './components/works';
@import './components/people';
@import './components/contact';

@import './components/caseNumbers';
@import './components/caseFullVideo';

@import './components/footer';

// themes
@import './themes/dark';
@import './themes/festival';