.group {
	position: relative;

	width: 100%;
	max-height: 0;
	font-size: 0;

	background-color: $white;

	z-index: 10;
	overflow: hidden;
	transition: max-height 500ms;

	.group__close {
		position: absolute;
		top: 16px;
		right: 16px;

		width: 16px;
		height: 16px;

		z-index: 2;

		.group__closeBar {
			position: absolute;
			top: 0;
			left: 0;

			width: 1px;
			height: 16px;
			background-color: $black;

			&:first-child {
				transform: rotate(-45deg);
			}

			&:last-child {
				transform: rotate(45deg);
			}
		}
	}

	.group__logoMobile {
		display: inline-block;
		vertical-align: bottom;
		width: 80px;
		margin-bottom: 32px;
	}

	.group__content {
		display: inline-block;
		vertical-align: bottom;
		width: calc(100% - 80px);
		padding: 32px 0;
		text-align: left;

		.group__logoText {
			margin-bottom: 24px;
		}

		p {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			font-family: 'Agrandir Narrow', sans-serif;
			font-size: 1.4rem;
			text-transform: uppercase;
			line-height: 1.3em;
			letter-spacing: 1.2px;
		}

		.group__list {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				vertical-align: bottom;
				margin-right: 50px;

				a {
					display: block;
					font-family: 'Agrandir', sans-serif;
					font-size: 1.4rem;
					color: $black;
					font-weight: bold;
					text-transform: uppercase;
					text-decoration: none;
					letter-spacing: 1px;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&--visible {
		max-height: 300px;
	}

	&--noTransition {
		transition: initial;
	}
}

@include above ($desk-bk) {

	.group {
		.group__logo {
			position: absolute;
			bottom: 0;
			right: 20px;
	
			width: 50%;
			height: 100%;
	
			background-image: url('../img/logos/dvt-white-bg.svg');
			background-size: 365px;
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: right;
		}

		.group__logoMobile {
			display: none;
		}

		.group__close {
			top: 16px;
			left: 50%;
			right: initial;
			transform: translateX(-50%);
		}

		.group__content {
			.group__logoText {
				margin-bottom: 32px;
			}

			.group__logo {
				max-width: 70px;
			}

			p {
				font-size: 1.6rem;
			}
		}
	}

}

@include above ($large-desk-bk) {

	.group {
		.group__logo {
			right: 36px;
			background-size: 425px;
		}

		.group__close {
			top: 24px;
		}

		.group__content {
			padding: 48px 0 32px;

			p {
				font-size: 1.8rem;
			}
		}

	}

}