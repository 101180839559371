.caseFullVideo {
	position: relative;
	height: 500px;
	background-color: $festival;
	transition: background-color 250ms;

	.caseFullVideo__backgroundImage {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
	}

	.caseFullVideo__play {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: 0;
		opacity: .9;
		cursor: pointer;
		z-index: 3;
		transform: translate(-50%, -50%);
		transition: margin-top 500ms, opacity 600ms;

		svg {
			width: 64px;
			fill: $white;
		}
	}

	video {
		display: none;
		width: 100%;
	}

	.caseFullVideo__container {
		position: relative;
		height: 100%;
		z-index: 5;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .35);
			z-index: 3;
		}
	}

	&--negativeMargin {
		margin-top: -50px;
	}
}

@include above ('1024px') {

	.caseFullVideo {
		height: auto;

		.caseFullVideo__backgroundImage {
			display: none;
		}

		.caseFullVideo__play {
			margin-top: -100px;
			opacity: 0;

			svg {
				width: 84px;
			}
		}

		video.videoLoaded {
			display: block;
		}

		&:hover {
			.caseFullVideo__play {
				margin-top: 0;
				opacity: .5;
	
				&:hover {
					opacity: .8;
				}
			}
		}
	}

}

@include above ($desk-bk) {

	.caseFullVideo {
		&--negativeMargin {
			margin-top: -100px;
		}
	}
	
}