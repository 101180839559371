.aboutDiverti {
	position: relative;
	padding: 60px 0;
	background-color: $orange;
	overflow: hidden;

	.aboutDiverti__title {
		position: relative;
		margin: 0 0 30px;
		font-family: $grand-font-family;
		font-size: 3.6rem;
		color: $white;
		z-index: 2;
	}

	.aboutDiverti__text {
		position: relative;
		z-index: 2;

		p {
			margin: 12px 0;
			font-family: $main-font-family;
			font-size: 1.4rem;
			color: $white;
			line-height: 1.6em;
			letter-spacing: 1.1px;
	
			&:first-child {
				margin-top: 0;
			}
	
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.aboutDiverti__logo {
		position: absolute;
		top: 0;
		left: 0;
		height: 102%;

		svg {
			fill: #fa724b;
		}
	}

	.aboutDiverti__rainbow {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		font-size: 0;

		.aboutDiverti__rainbowBar {
			display: inline-block;
			width: 2px;
			height: 100%;

			&:nth-child(1) {
				background-color: $white;
			}

			&:nth-child(2) {
				background-color: $orange;
			}

			&:nth-child(3) {
				background-color: $blue;
			}

			&:nth-child(4) {
				background-color: $green;
			}

			&:nth-child(5) {
				background-color: $pink;
			}
		}
	}

	&--blue {
		background-color: $blue;

		.aboutDiverti__title {
			color: $green;
		}

		.aboutDiverti__text {
			p {
				color: $green;
			}
		}

		.aboutDiverti__logo {
			svg {
				fill: lighten($color: $blue, $amount: 2);
			}
		}
	}
}

@include above ($desk-bk) {

	.aboutDiverti {
		padding: 100px 0;

		.aboutDiverti__title {
			font-size: 5.8rem;
			line-height: 1.1em;
		}

		.aboutDiverti__text {
			p {
				font-size: 1.5rem;
			}
		}

		.aboutDiverti__rainbow {
			.aboutDiverti__rainbowBar {
				width: 5px;
			}
		}
	}

}

@include above ($large-desk-bk) {

	.aboutDiverti {
		padding: 140px 0;

		.aboutDiverti__title {
			font-size: 7.8rem;
			line-height: 1.1em;
		}

		.aboutDiverti__text {
			p {
				margin: 20px 0;
				font-size: 1.8rem;
			}
		}

		.aboutDiverti__rainbow {
			.aboutDiverti__rainbowBar {
				width: 20px;
			}
		}
	}

}